import { RewardType } from '@wix/ambassador-loyalty-v1-reward/types';
import { useSettings } from '@wix/tpa-settings/react';
import { Confirm as ConfirmIcon } from '@wix/wix-ui-icons-common';
import React from 'react';
import { Button, ButtonPriority, ButtonSize } from 'wix-ui-tpa/cssVars';

import { DataHook } from '../../../types/data-hook';
import { SimpleReward } from '../../../types/domain';
import settingsParams from '../settingsParams';
import { classes } from './list-item-button.st.css';
import { RewardProgress } from './reward-progress';

interface ListItemProps {
  onClick(): void;
  reward: SimpleReward;
  showCopied?: boolean;
  isCheckoutDiscountReward: boolean;
  hasEnoughPoints: boolean;
}

export const ListItemButton: React.FC<ListItemProps> = ({
  onClick,
  reward,
  showCopied,
  isCheckoutDiscountReward,
  hasEnoughPoints,
}) => {
  const settings = useSettings();
  const isDiscountReward = reward.type === RewardType.DISCOUNT_AMOUNT;
  const isCouponReward = reward.type === RewardType.COUPON_REWARD;
  const isRedeemedCouponReward = reward.couponActivated;

  if (isDiscountReward && hasEnoughPoints) {
    if (isCheckoutDiscountReward) {
      return (
        <Button
          className={classes.secondaryButton}
          priority={ButtonPriority.secondary}
          size={ButtonSize.small}
          onClick={onClick}
          upgrade
        >
          {settings.get(settingsParams.continueButton)}
        </Button>
      );
    } else {
      return (
        <Button
          data-hook={DataHook.RewardsListItemButtonApplyReward}
          className={classes.primaryButton}
          size={ButtonSize.small}
          onClick={onClick}
          upgrade
        >
          {settings.get(settingsParams.button)}
        </Button>
      );
    }
  }

  if (isCouponReward) {
    if (isRedeemedCouponReward) {
      return (
        <Button
          priority={ButtonPriority.secondary}
          data-hook={DataHook.RewardsListItemButtonCouponCopy}
          size={ButtonSize.small}
          onClick={onClick}
          upgrade
          className={classes.secondaryButton}
        >
          <div className={showCopied ? classes.container : ''}>
            {showCopied && <ConfirmIcon size="24px" className={classes.confirmIcon} />}
            <div className={showCopied ? classes.buttonText : ''}>{settings.get(settingsParams.copyButton)}</div>
          </div>
        </Button>
      );
    } else if (hasEnoughPoints) {
      return (
        <Button
          data-hook={DataHook.RewardsListItemButtonCouponGet}
          className={classes.primaryButton}
          size={ButtonSize.small}
          onClick={onClick}
          upgrade
        >
          {settings.get(settingsParams.getButton)}
        </Button>
      );
    }
  }

  return <RewardProgress reward={reward} />;
};
